<template>
  <section class="all-menus">
    <draggable
      v-model="navigationList"
      :options="{
        handle: '.draggable-item__handle',
        ghostClass: 'ghost',
        dragClass: 'sortable-drag'
      }"
      @start="drag=true"
      @end="drag=false">

      <transition-group name="list-complete" tag="ol">
        <li
          v-for="(nav, index) in navigationList"
          :key="nav.navigationID"
          class="draggable-item">

            <div class="draggable-item__row">

              <div class="draggable-item__handle">
                <transition name="fade">
                  <v-icon class="draggable-item__handle-icon">open_with</v-icon>
                </transition>

                <p class="draggable-item__name draggable-item__name--centered">{{ nav.name }}</p>
              </div>

              <div class="draggable-item__actions">
                <button class="draggable-item__action" type="button" @click="deleteMenu(nav.navigationID, index)">
                  <v-icon>delete</v-icon>
                </button>

                <button class="draggable-item__action" type="button" @click="editMenu(nav.navigationID)">
                  <v-icon>edit</v-icon>
                </button>
              </div>
            </div>

        </li>
      </transition-group>

    </draggable>

    <v-footer fixed inset height="60" class="pa-3">
      <v-spacer></v-spacer>
      <v-btn
        @click="addMenu()"
        color="accent"
        type="button"
        depressed
      >
        <v-icon small>add</v-icon>
        Add Navigation
      </v-btn>
    </v-footer>

  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"

export default {
  name: "NavigationAll",

  components: {
    draggable
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/navigations`,
    navigationList: []
  }),

  mounted() {
    console.log("Navigation All mounted");

    this.fetchMenus();
  },

  methods: {
    fetchMenus() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log(response);
          this.navigationList = response.data.body;

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving menus", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    addMenu() {
      console.log("Add menu");
      this.$router.push({ path: "/navigation/create" })
    },

    editMenu(menuId) {
      console.log("Edit Menu", menuId);
      this.$router.push({ path: `/navigation/${menuId}` })
    },

    deleteMenu(menuId, index) {
      console.log("... attempting to delete menu")
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios.delete(`${this.api}/${menuId}`, { "headers": { "Authorization": jwt }} )
        .then(() => {
          this.fetchMenus();
        })
      })
    }
  }
}

</script>

<style lang="scss" scoped>
  .draggable-item {
    transition: all 0.5s, opacity 0.2s;

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    &__handle {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding: 15px;
      cursor: -webkit-grab;
      cursor: grab;
    }

    &__handle-icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    &__name {
      flex-grow: 1;
      padding: 0;
      margin: 0 0 0 45px;
      text-align: left;

      &--centered {
        text-align: center;
      }
    }

    &__actions {
      padding: 0 15px;
      width: 130px;
    }

    &__action {
      margin: 0 0 0 25px;
    }
  }

  .ghost .draggable-item__row {
    background-color: rgba(103, 36, 124, 0.2);
  }

  .sortable-chosen .draggable-item__row {
    border: 2px solid #67247C;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
  }

</style>
